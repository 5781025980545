import React from 'react'
import './Works.css'
import Upwork from '../../img/Upwork.png'
import Fiverr from '../../img/fiverr.png'
import Amazon from '../../img/amazon.png'
import Shopify from '../../img/Shopify.png'
import Facebook from '../../img/Facebook.png'
import { themeContext } from '../../Context'
import { useContext } from 'react'
const Works = () => {
    const theme=useContext(themeContext)
    const darkMode=theme.state.darkMode;
    return (
        <div className='works' id='Portfolio'>
            <div className='awesome'>
                <span  style={{ color: darkMode ? "white" : "" }}>Work for all these</span>
                <span>Brands and Clients</span>
                <span>Project Name: E-commerce Website Development
                    <br />
                    Description: Collaborated with a team to design and develop a feature-rich e-commerce 
                    <br/>
                    platform using the MERN stack. Integrated secure payment gateways, real-time 
                    <br/>
                    inventory tracking, and user-friendly product management.
                </span>
                
                    <button className='button s-button'>Hire me</button>
                

                <div className='blur s-blur' style={{ background: '#ABF1FF94' }}></div>
            </div>
            {/* right side */}
            <div className='w-right'>
                <div className='w-mainCircle'>
                    <div className='w-secCircle'>
                        <img src={Upwork} alt=''/>
                    </div>
                    <div className='w-secCircle'>
                        <img src={Fiverr} alt=''/>
                    </div>
                    <div className='w-secCircle'>
                        <img src={Amazon} alt=''/>
                    </div>
                    <div className='w-secCircle'>
                        <img src={Shopify} alt=''/>
                    </div>
                    <div className='w-secCircle'>
                        <img src={Facebook} alt=''/>
                    </div>
                </div>
                {/* background circle  */}
                <div className='w-backCircle blueCircle'></div>
                <div className='w-backCircle yellowCircle'></div>
            </div>
        </div>
    )
}

export default Works