import React from 'react'
import './Footer.css'
import Wave from '../../img/wave.png'
import Insta from '@iconscout/react-unicons/icons/uil-instagram'
import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'
const Footer = () => {
  return (
    <div className='footer'>
        <img src={Wave} alt='' style={{width:'100%'}}/>
        <div className='f-content'>
            <span>mirzaumaish1961@gmail.com</span>
            <div className='f-icons'>
                <a href='https://www.instagram.com/x_style_mughal/'>
                <Insta color='white' size='3rem'/>
                </a>
                
                <a href='https://www.facebook.com'>
                <Facebook color='white' size='3rem'/>
                </a>
                <a href='https://www.linkedin.com/in/abdul-rehman-79879a237/'>
                <Linkedin color='white' size='3rem'/>
                </a>
                
            </div>
        </div>
    </div>
  )
}

export default Footer