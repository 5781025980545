import React from 'react'
import './Portfolio.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Slidebar from '../../img/sidebar.png'
import Ecommerce from '../../img/ecommerce.png'
import HOC from '../../img/hoc.png'
import MusicApp from '../../img/musicapp.png'
import { themeContext } from '../../Context'
import { useContext } from 'react'
const Portfolio = () => {
    const theme=useContext(themeContext)
    const darkMode=theme.state.darkMode;
    return (
        <div className='portfolio'>
            {/* Heding */}
            <span style={{ color: darkMode ? "white" : "" }}>React Projects</span>
            <span>Portfolio</span>

            {/* Slider */}
            <Swiper
            spaceBetween={30}
            slidesPerView={3}
            grabCursor={true}
            className='portfolio-slider'
            >
                <SwiperSlide>
                    <img src={Slidebar} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Ecommerce} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={MusicApp} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={HOC} alt='' />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Portfolio