import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Card from '../Card/Card'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import { themeContext } from '../../Context'
import { useContext } from 'react'
const Services = () => {
    const theme=useContext(themeContext)
    const darkMode=theme.state.darkMode;
  return (
    <div className='services' id='services'>
{/* Left Side */}
<div className='awesome'>
<span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
<span>Services</span>
<span>Frontend Development with React. Backend Development with Node.js and Express.
    <br/>
    Database Design and Management
</span>
<a href='https://export-download.canva.com/YN3gQ/DAFrP6YN3gQ/45/0-2612487695495782231.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJHKNGJLC2J7OGJ6Q%2F20230816%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230816T105045Z&X-Amz-Expires=19840&X-Amz-Signature=de3cd4d6b2853947408197971d050ac0d21bc6589d10b54eeae55863c254f438&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%2A%3DUTF-8%27%27mirzaumaish1961%2540gmail.com.pdf&response-expires=Wed%2C%2016%20Aug%202023%2016%3A21%3A25%20GMT'>
<button className='button s-button'>Download CV</button>
</a>

<div className='blur s-blur' style={{background:'#ABF1FF94'}}></div>
</div>
{/* right Side */}
<div className='cards'>
<div style={{left:'14rem'}}>
    <Card
    emoji={HeartEmoji}
    heading={'Design'}
    detail={"figma, Sketch, Photoshop, Adobe, Adobe xd"}
    />
</div>
<div style={{left:'-4rem',top:'12rem'}}>
    <Card
    emoji={Glasses}
    heading={'Developer'}
    detail={"Html, Css, JavaScript, React,  NodeJs, BootStrap"}
    />
</div>
<div style={{left:'12rem',top:'19rem'}}>
    <Card
    emoji={Humble}
    heading={'UI/UX'}
    detail={"My mission is to craft digital experiences that seamlessly blend aesthetics with usability."}
    />
</div>
<div className='blur s-blur2' style={{background:'var(--purple)'}}></div>
</div>
    </div>
  )
}

export default Services