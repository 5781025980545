import React from 'react'
import './Experience.css'
const Experience = () => {
  return (
    <div className='experience' id='experiences'>
        <div className='achivement'>
            <div className='circle'>
                2+
            </div>
            <span>Years</span>
            <span>Experience</span>
        </div>
        <div className='achivement'>
            <div className='circle'>
                20+
            </div>
            <span>Completed Projects</span>
            <span>Experience</span>
        </div>
        <div className='achivement'>
            <div className='circle'>
                5+
            </div>
            <span>Companies</span>
            <span>Work</span>
        </div>
    </div>
  )
}

export default Experience