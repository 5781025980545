import React from 'react'
import './Card.css'
const Card = ({emoji,detail,heading}) => {
  return (
    <div className='card'>
      <img src={emoji} alt=''/>
      <span>{detail}</span>
      <span>{heading}</span>
      <button className='c-button'>LEARN MORE</button>
    </div>
  )
}

export default Card