import React, { useContext } from 'react'
import './Intro.css'
import Github from '../../../src/img/github.png'
import LinkedIn from '../../../src/img/linkedin.png'
import Instagram from '../../../src/img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import boy from '../../img/boy.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import Crown from '../../img/crown.png'
import thumbup from '../../img/thumbup.png'
import glassesimoji from '../../img/glassesimoji.png'
import { themeContext } from '../../Context'
const Intro = () => {
    const transition = { duration: 2, type: "spring" };
    const theme=useContext(themeContext)
    const darkMode=theme.state.darkMode;
    return (
        <div className='intro' id='home'>
            <div className='i-left'>
                <div className='i-name'>
                    <span style={{ color: darkMode ? "white" : "" }}>Hy! I AM</span>
                    <span>Abdul Rehman</span>
                    <span>Mern Stack Developer with high level of experience in web designing and development, producting the Quality work</span>
                </div>
                <button className='button i-button'>Hire Me</button>
                <div className='i-icons'>
                    <a href='https://www.google.com/'>
                        <img src={Github} alt='' />
                    </a>
                    <a href='https://www.linkedin.com/in/abdul-rehman-79879a237/'>
                        <img src={LinkedIn} alt='' />
                    </a>
                    <a href='https://www.instagram.com/x_style_mughal/'>
                        <img src={Instagram} alt='' />
                    </a>

                </div>
            </div>
            <div className='i-right
        '>
                <img src={Vector1} alt='' />
                <img src={Vector2} alt='' />
                <img src={boy} alt='' />
                <img
                   initial={{ left: "-36%" }}
                   whileInView={{ left: "-24%" }}
                   transition={transition}
                 src={glassesimoji} alt=''/>

                 

                <div style={{ top: '-4', left: '68%' }}
                className='floating-div'
                >
                    <FloatingDiv image={Crown} txt1="Web" txt2="Developer" />
                </div>
                <div style={{ top: '18rem', left: '0%' }}
                className='floating-div'
                >
                    <FloatingDiv image={thumbup} txt1='Best Design' txt2="Award" />
                </div>
                {/* Blur Divs */}
                <div className='blur' style={{ background: 'rgb(238 210 255)' }}></div>
                <div className='blur'
                    style={{
                        background: '#C1F5FF',
                        top: '17rem',
                        width: '21rem',
                        height: "11rem",
                        left:"-9rem"
                    }}
                ></div>
            </div>
        </div>
    )
}

export default Intro 